import React, { useState } from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import Questions from '../data/questions'

const FAQ = () => {
  const [showFAQ, setShowFAQ] = useState(false)
  return (
    <FAQwrapper>
      <FAQcontent>
        <Fade delay={250}>
          <div>
            {/* eslint-disable-next-line */}
          <h2 onClick={() => setShowFAQ(!showFAQ)}>
              Have Questions? <span>View our FAQ</span>.
            </h2>
          </div>
        </Fade>
        {showFAQ &&
          Questions.map((q, index) => (
            <Fade delay={500}>
              <QuestionWrapper key={index}>
                <p>
                  <strong>{q.question}</strong>
                </p>
                <p>
                  {q.answer}{' '}
                  {q.link && (
                    <a href={q.link} target="_blank" rel="noopener noreferrer">
                      See map.
                    </a>
                  )}
                </p>
              </QuestionWrapper>
            </Fade>
          ))}
      </FAQcontent>
    </FAQwrapper>
  )
}

export default FAQ

// Component Styles
const FAQwrapper = styled.section`
  background: ${props => props.theme.colors.dark};
  padding: ${props => props.theme.utils.xxl} 0;
`

const FAQcontent = styled.div`
  color: ${props => props.theme.colors.light};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${props => props.theme.utils.xl};
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  div:first-of-type {
    grid-column: span 2;
    h2 {
      font-size: ${props => props.theme.utils.md};
      text-align: center;
      cursor: pointer;
      span {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  a {
    color: ${props => props.theme.colors.primary};
    :hover {
      color: ${props => props.theme.colors.dark};
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    div:first-of-type {
      grid-column: span 1;
    }
  }
`

const QuestionWrapper = styled.div`
  background-color: ${props => props.theme.colors.light};
  padding: ${props => props.theme.utils.lg};
  border-top: ${props => props.theme.utils.xxs} solid
    ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.dark};
  p:first-of-type {
    margin-bottom: ${props => props.theme.utils.sm};
    color: ${props => props.theme.colors.primary};
  }
`
