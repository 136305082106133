import React from 'react'
import styled from '@emotion/styled'
import { FaCoffee, FaUserFriends, FaClock, FaCheck } from 'react-icons/fa'
import Fade from 'react-reveal/Fade'
import PackageOptions from '../data/options'

const Package = () => (
  <>
    <Fade bottom delay={500}>
      {PackageOptions.map((p, index) => (
        <Option key={index}>
          <h3>{p.name}:</h3>
          <p>{p.desc}</p>
          <ul>
            <li>
              <FaCoffee />
              {p.baristas} {p.baristas === '1' ? 'barista' : 'baristas'}
            </li>
            <li>
              <FaUserFriends />
              {p.serve} people
            </li>
            <li>
              <FaClock />
              {p.time} hour minimum
            </li>
          </ul>
        </Option>
      ))}
      <Option id="faq">
        <h3>Add ons:</h3>
        <ul className="addons">
          <li>
            <FaCheck />
            <i>Custom labeled coffee packs and party favors.</i>
          </li>
          <li>
            <FaCheck />
            <i>
              Mixed drinks, like espresso martinis, white russians, irish
              coffee, and caffe correttos.
            </i>
          </li>
          <li>
            <FaCheck />
            <i>Custom sleeves and napkins.</i>
          </li>
          <li>
            <FaCheck />
            <i>
              Unlock specialty menu items, like size options, syrups, and milks.
            </i>
          </li>
          <li>
            <FaCheck />
            <i>
              Combining packages. For example: Combine the coffee and hot
              chocolate bar.
            </i>
          </li>
        </ul>
      </Option>
    </Fade>
  </>
)

const Packages = () => (
  <PackagesWrapper id="packages">
    <PackagesContent>
      <Fade delay={250}>
        <div>
          <h2>Our Packages:</h2>
        </div>
      </Fade>
      <Package />
    </PackagesContent>
  </PackagesWrapper>
)

export default Packages

// Component Styles
const PackagesWrapper = styled.section`
  background: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.utils.xxl} 0;
`

const PackagesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${props => props.theme.utils.xl};
  max-width: 1000px;
  width: calc(100% - ${props => props.theme.utils.xl});
  margin: 0 auto;
  div:first-of-type {
    grid-column: span 3;
    h2 {
      font-size: ${props => props.theme.utils.lg};
      color: ${props => props.theme.colors.light};
    }
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    div:first-of-type {
      grid-column: span 1;
    }
  }
`

const Option = styled.div`
  background-color: ${props => props.theme.colors.light};
  padding: ${props => props.theme.utils.lg};
  border-top: ${props => props.theme.utils.xxs} solid
    ${props => props.theme.colors.dark};
  h3 {
    color: ${props => props.theme.colors.dark};
    font-size: ${props => props.theme.utils.md};
    margin-bottom: ${props => props.theme.utils.md};
  }
  ul {
    margin-top: ${props => props.theme.utils.md};
    li {
      display: flex;
      align-items: center;
      margin-bottom: ${props => props.theme.utils.xs};
      font-size: ${props => props.theme.utils.sm};
      color: ${props => props.theme.colors.primary};
      svg {
        min-width: 20px;
        color: ${props => props.theme.colors.primary};
        margin-right: ${props => props.theme.utils.xs};
      }
      i {
        color: ${props => props.theme.colors.primary};
      }
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`
