const Questions = [
  {
    question: 'Are you out of Tyler?',
    link:
      'https://www.mapdevelopers.com/draw-circle-tool.php?circles=%5B%5B40233.5%2C32.343546%2C-95.310258%2C%22%23AAAAAA%22%2C%22%23000000%22%2C0.4%5D%5D',
    answer:
      'Yes, though we will serve a radius of up to 4 hrs away. I charge a travel fee of ¢.58 per mile outside of a 25 mi radius from the center of Tyler.',
  },
  {
    question: 'Where are you located?',
    answer: 'We do not have a location. We are only an on demand service.',
  },
  {
    question: 'How long have you been in business?',
    answer: 'We launched in May of 2018',
  },
  {
    question: 'What gave you the idea for this type of business?',
    answer: 'I hired a barista at my wedding and the idea stuck.',
  },
  {
    question: 'What types of events do you cater?',
    answer:
      "I have catered weddings, office/teacher appreciation days, End of Eil, churches and church retreats, open houses, a Halloween party, a Friendsgiving, Mother's Day, Walk for the Cure, anniversary parties, birthdays, baby showers,wedding showers, graduations, the list goes on.",
  },
  {
    question: 'What are your rates?',
    answer:
      'Please send me an email and we will get the ball rolling! I will say however, my most popular rate is for 2 hrs for 50-75 people and that runs 325 + tax, at its least expensive. There are other variables that might affect the end charge but hopefully that gives you an idea if what we charge.',
  },
  {
    question: 'How much space do you need?',
    answer:
      "It depends on the cart but usually 6'x6' is plenty and I only need the use of a regular 110v wall outlet.",
  },
  {
    question: 'Do you always serve mixed drinks?',
    answer:
      'I do not always serve mixed drinks. I have the license and tools to serve mixed drinks, but it is relatively uncommon. Probably only 10% of my gigs even hire me for this aspect of my service. I am most frequently serving schools, churches, offices, and weddings. A lot of my clients will actually hire me as a fun, nonalcoholic version of a bar.',
  },
]

export default Questions
