const PackageOptions = [
  {
    name: 'Basic Coffee Bar',
    desc:
      'Comes with 3 coffee urns, cream, sugar, cups, stirrers, and napkins.',
    baristas: '1',
    serve: '10-75',
    time: 1,
  },
  {
    name: 'Hot Chocolate Bar',
    desc:
      'Comes with 3 hot chocolate urns, whipped cream, drizzles, sprinkles, cups, stirrers, and napkins.',
    baristas: '1',
    serve: '10-75',
    time: 1,
  },
  {
    name: 'The Mini Bar',
    desc:
      "The 4' version of our popular coffee bar with an additional barista. Perfect for small gatherings and events.",
    baristas: '2',
    serve: '15-100',
    time: 1,
  },
  {
    name: 'The Full Bar',
    desc:
      "The 6' version of our popular coffee bar with an additional barista or two. Perfect for medium-sized events.",
    baristas: '2-3',
    serve: '50-225',
    time: 2,
  },
  {
    name: 'The Cadillac',
    desc:
      "Includes both the 4' and 6' coffee bars with several baristas. Perfect for large events. ",
    baristas: '3-5',
    serve: '200-325',
    time: 2,
  },
]

export default PackageOptions
